import DialogContent from '@mui/material/DialogContent'
import { Dispatch, SetStateAction } from 'react'
import TagEdit from './TagEdit'
import { AddCircle, RemoveCircle } from '../../components/icons'
import {
  SelectedTags,
  UpdateTag,
  UpdateTagCategory,
} from './TransactionListBulkEdit.types'
import { TagCategory } from '../../components/customCharts/CustomChartState'

type TransactionListBulkEditContentProps = {
  selectedTags: SelectedTags
  setSelectedTags: Dispatch<SetStateAction<SelectedTags>>
  tagCategories: TagCategory[]
  totalTransactionNumber: number
}

const TransactionListBulkEditContent = ({
  selectedTags,
  setSelectedTags,
  tagCategories,
  totalTransactionNumber,
}: TransactionListBulkEditContentProps) => {
  const updateTag: UpdateTag = (tag, index) => {
    setSelectedTags((prev) => {
      prev[index][1] = tag
      return [...prev]
    })
  }

  const updateTagCategory: UpdateTagCategory = (tagCategory, index) => {
    setSelectedTags((prev) => {
      prev[index][0] = tagCategory
      return [...prev]
    })
  }

  return (
    <DialogContent>
      <p>
        This will update the tag categories for transactions across all pages (
        {totalTransactionNumber} transaction
        {totalTransactionNumber > 1 ? 's' : ''}).
      </p>
      {selectedTags.map((selectedTag, i) => (
        <TagEdit
          key={selectedTag[0]?.id || i}
          selectedTag={selectedTag}
          updateTag={(tag) => updateTag(tag, i)}
          updateTagCategory={(tagCategory) => updateTagCategory(tagCategory, i)}
          tagCategories={tagCategories}
        />
      ))}
      <div className="flex">
        <AddCircle
          onClick={() => setSelectedTags((prev) => [...prev, [null, null]])}
        />
        <RemoveCircle
          disabled={selectedTags.length <= 1}
          onClick={() => {
            if (selectedTags.length > 1) {
              setSelectedTags((prev) => prev.slice(0, prev.length - 1))
            }
          }}
        />
      </div>
    </DialogContent>
  )
}

export default TransactionListBulkEditContent
