import {
  ArrayInput,
  AutocompleteInput,
  FormDataConsumer,
  ReferenceInput,
  SimpleFormIterator,
} from 'react-admin'

const TagsInput = ({ source }) => {
  const handleChange = (e) => {
    console.log(e)
  }
  return (
    <ArrayInput source={source}>
      <SimpleFormIterator inline>
        <ReferenceInput
          sort={{ field: 'name', order: 'ASC' }}
          source="tag_category.id"
          reference="tag_categories"
          perPage={10000}
        >
          <AutocompleteInput
            optionText="name"
            optionValue="id"
            label="Tag category"
            sx={{ width: 300 }}
            fullWidth
            onChange={handleChange}
          />
        </ReferenceInput>
        <FormDataConsumer>
          {({
            scopedFormData, // The data for this item of the ArrayInput
            getSource = () => {
              return ''
            }, // A function to get the valid source inside an ArrayInput
          }) => {
            if (
              !scopedFormData ||
              !scopedFormData.tag_category ||
              !scopedFormData.tag_category ||
              !scopedFormData.tag_category.id
            )
              return null

            return (
              <ReferenceInput
                reference="tags"
                source={getSource('id')}
                sort={{ field: 'name', order: 'ASC' }}
                filter={{
                  tag_category_id: scopedFormData.tag_category.id,
                }}
                perPage={10000}
              >
                <AutocompleteInput
                  optionText="name"
                  optionValue="id"
                  label="Tag name"
                  sx={{ width: 300 }}
                  fullWidth
                  onChange={handleChange}
                />
              </ReferenceInput>
            )
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  )
}

export default TagsInput
