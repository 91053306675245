import { FC } from 'react'

import Flag from 'react-world-flags'
import { useTranslation } from 'react-i18next'
import {
  formatCurrency,
  formatNumber,
  getLabelSuffix,
  sanitiseCountryCode,
} from '../../helpers'
import FullText from '../../components/common/FullText'

interface Props {
  // eslint-disable-next-line no-unused-vars
  onClick: (id: number) => void
  vendorFilterData?: any
  data: any
}

export const VendorChildTable: FC<Props> = ({
  onClick,
  vendorFilterData = [],
  data,
}) => {
  const { t } = useTranslation()
  const suffix = getLabelSuffix()

  const shouldBeGreen = (vendorId) => {
    if (vendorFilterData.length) {
      return vendorFilterData.find((v) => v.vendor.id === vendorId)
    }
    return false
  }

  return (
    <div className="border border-stone-200/50 rounded-md overflow-hidden">
      <table className="table-auto table w-full font-medium">
        <thead className="bg-stone-100 py-1">
          <tr className="text-left text-sm">
            <th className="py-2 pr-3 font-semibold pl-6" colSpan={2}>
              {t('name')}
            </th>
            <th className="py-2 pl-3 pr-6 font-semibold text-right">
              {t(`total_spend${suffix}`)}
            </th>
            <th className="py-2 pl-3 pr-6 font-semibold">{t('percentage')}</th>
          </tr>
        </thead>
        <tbody>
          {data.map((group) => (
            <tr
              key={group.id}
              className={`text-sm border-b border-stone-200/50 active cursor-pointer ${
                shouldBeGreen(group.id) ? 'bg-green-100' : ''
              } `}
              onClick={() => onClick(group.id)}
            >
              <td className="w-12 pl-6">
                <Flag
                  code={sanitiseCountryCode(group?.territory?.name)}
                  className="w-6"
                />
              </td>
              <td className="py-2 pr-3 pl-2 capitalize group relative max-w-[16rem]">
                <div className="absolute pointer-events-none -top-0 opacity-0 group-hover:opacity-100">
                  <FullText
                    text={group.name}
                    className={
                      shouldBeGreen(group.id) ? 'bg-green-100' : 'bg-white'
                    }
                  />
                </div>
                <div className="truncate max-w-[16rem] opacity-100 group-hover:opacity-0">
                  {group.name}
                </div>
              </td>
              <td className="py-3 pl-3 pr-6 font-semibold border-l border-stone-200/50 text-right">
                {group.total_spend
                  ? formatCurrency(group.total_spend / 100)
                  : formatCurrency(0)}
              </td>
              <td className="py-3 pl-3 pr-6 font-semibold border-l border-stone-200/50">
                {group.percentage > 0
                  ? `${formatNumber(group.percentage)}%`
                  : `${formatNumber(0)}%`}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
