import { FunctionComponent, useMemo } from 'react'
import { ResponsiveBar } from '@nivo/bar'

import { Loading } from 'react-admin'
import { NoData } from '../NoData'
import { formatCurrency, formatCurrencyCompact } from '../../helpers'
import { COLOR_PALETTE_BLUE, COLOR_PALETTE_GREEN } from '../colors'

const theme = {
  grid: {
    line: {
      stroke: '#f5f5f5',
    },
  },
}

interface Props {
  data: any
  showTotalSpend: boolean
  isLoading: boolean
}

const margin = { top: 35, right: 5, bottom: 35, left: 5 }

const TopVendorsChart: FunctionComponent<Props> = ({
  data,
  showTotalSpend,
  isLoading,
}) => {
  const height = useMemo(
    () => 45.5 * data.length + (margin.top + margin.bottom),
    [data]
  )

  if (isLoading) {
    return <Loading loadingSecondary="" />
  }

  if (!data.length) {
    return (
      <div style={{ height }}>
        <NoData />
      </div>
    )
  }

  return (
    <div style={{ height }}>
      <ResponsiveBar
        theme={theme}
        layout="horizontal"
        data={[...data].reverse()}
        margin={margin}
        enableLabel={false}
        enableGridX
        enableGridY={false}
        borderRadius={3}
        padding={0.1}
        colors={showTotalSpend ? COLOR_PALETTE_BLUE : COLOR_PALETTE_GREEN}
        valueFormat={(v) => formatCurrency(v)}
        // @ts-ignore
        tooltipLabel={(v) => v?.data?.label || ''}
        axisBottom={{
          format: (value) =>
            showTotalSpend ? formatCurrencyCompact(value) : value,
        }}
        colorBy="indexValue"
        axisLeft={null}
        borderWidth={1.1}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 0.3]],
        }}
      />
    </div>
  )
}

export default TopVendorsChart
