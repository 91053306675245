import { RemoveCircle as MuiRemoveCircle } from '@mui/icons-material'
import { DefaultComponentProps } from '@mui/material/OverridableComponent'
import { SvgIconTypeMap } from '@mui/material'

const RemoveCircle = (
  props: DefaultComponentProps<SvgIconTypeMap> & { disabled?: boolean }
) => {
  const { className, disabled, ...restProps } = props
  return (
    <MuiRemoveCircle
      className={`${
        disabled ? 'opacity-50' : 'hover:opacity-80 cursor-pointer'
      } ${className}`}
      htmlColor="#4338CA"
      {...restProps}
    />
  )
}

export default RemoveCircle
