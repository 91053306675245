import { AuthProvider, Options } from 'react-admin'
import { fetchUtils } from 'ra-core'
import ReactGA from 'react-ga4'
import { hotjar } from 'react-hotjar'
import { CATEGORY_USER, EVENT_LOGGED_IN } from './constants'

const apiUrl = process.env.REACT_APP_ADMIN_API
const tokenTtl = 30 // minutes
const defaultCurrency = 'GBP'
const defaultNoun = 'SPEND'

const getAccessToken = () => {
  const token = localStorage.getItem('access_token')
  return Promise.resolve(token)
}

const httpClient = (url, options: Options = {}) => {
  const noptions = options

  if (!noptions.headers) {
    noptions.headers = new Headers({ Accept: 'application/json' })
  }
  return getAccessToken().then((token) => {
    if (token) {
      noptions.headers = new Headers(noptions.headers)
      noptions.headers.set('Authorization', `Bearer ${token}`)
    }
    return fetchUtils.fetchJson(url, noptions)
  })
}

const authProvider: AuthProvider = {
  login: ({ username, password }) => {
    const url = `${apiUrl}/auth/jwt/login`

    const formData = new FormData()
    formData.append('username', username)
    formData.append('password', password)

    return httpClient(url, {
      method: 'POST',
      body: formData,
    })
      .then(({ json }) => {
        localStorage.setItem('access_token', json.access_token)
        const dt = new Date()
        localStorage.setItem('access_token_claimed', dt.toString())
        dt.setMinutes(dt.getMinutes() + tokenTtl)
        localStorage.setItem('access_token_expires', dt.toString())

        // persist filters for a user via LocalStorage
        const savedTxnFilters = localStorage.getItem(
          username.concat('_', 'txnFilters')
        )
        const savedTxnFiltersIdx = localStorage.getItem(
          username.concat('_', 'txnFilters_idx')
        )
        if (savedTxnFilters) {
          localStorage.setItem('txnFilters', savedTxnFilters)
          if (savedTxnFiltersIdx) {
            localStorage.setItem('txnFilters_idx', savedTxnFiltersIdx)
          } else {
            localStorage.setItem('txnFilters_idx', '-1')
          }
        }
        return Promise.resolve()
      })
      .catch(() => {
        return Promise.reject()
      })
  },
  logout: () => {
    // persist filters for a user via LocalStorage
    const user = localStorage.getItem('user')
    if (user) {
      const userBits = JSON.parse(user)
      const savedTxnFilters = localStorage.getItem('txnFilters')
      const savedTxnFiltersIdx = localStorage.getItem('txnFilters_idx')
      if (savedTxnFilters) {
        localStorage.setItem(
          userBits.email.concat('_', 'txnFilters'),
          savedTxnFilters
        )
        if (savedTxnFiltersIdx) {
          localStorage.setItem(
            userBits.email.concat('_', 'txnFilters_idx'),
            savedTxnFiltersIdx
          )
        } else {
          localStorage.setItem(
            userBits.email.concat('_', 'txnFilters_idx'),
            '-1'
          )
        }
      }
    }
    // JWT auth has no log out
    localStorage.removeItem('user')
    localStorage.removeItem('access_token')
    localStorage.removeItem('access_token_claimed')
    localStorage.removeItem('access_token_expires')
    localStorage.removeItem('txnFilters')
    localStorage.removeItem('txnFilters_idx')
    // localStorage.clear()
    return Promise.resolve()
  },
  checkAuth: () => {
    const token = localStorage.getItem('access_token')
    const tokenClaimed = localStorage.getItem('access_token_claimed')
    const tokenExpires = localStorage.getItem('access_token_expires')

    if (token === null || tokenExpires === null || tokenClaimed === null) {
      localStorage.removeItem('user')
      localStorage.removeItem('access_token')
      localStorage.removeItem('access_token_claimed')
      localStorage.removeItem('access_token_expires')
      localStorage.removeItem('txnFilters')
      localStorage.removeItem('txnFiltersIdx')
      // localStorage.clear()
      return Promise.reject()
    }

    const dt = new Date(tokenExpires)
    const now = new Date()
    const claimed = new Date(tokenClaimed)

    if (dt < now) {
      localStorage.removeItem('user')
      localStorage.removeItem('access_token')
      localStorage.removeItem('access_token_claimed')
      localStorage.removeItem('access_token_expires')
      localStorage.removeItem('txnFilters')
      localStorage.removeItem('txnFiltersIdx')
      // localStorage.clear()
      return Promise.reject()
    }

    const apiTokenExpires = claimed
    apiTokenExpires.setHours(apiTokenExpires.getHours() + 12)

    const nextNow = now
    nextNow.setMinutes(nextNow.getMinutes() + tokenTtl)

    if (apiTokenExpires < nextNow) {
      localStorage.removeItem('user')
      localStorage.removeItem('access_token')
      localStorage.removeItem('access_token_claimed')
      localStorage.removeItem('access_token_expires')
      localStorage.removeItem('txnFilters')
      localStorage.removeItem('txnFiltersIdx')
      // localStorage.clear()
      return Promise.reject()
    }

    localStorage.setItem('access_token_expires', nextNow.toString())

    return Promise.resolve()
  },
  checkError: (error) => {
    const { status } = error
    if (status === 401 || status === 403) {
      //    localStorage.removeItem('access_token');
      //    return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve()
  },
  getIdentity: () => {
    const url = `${apiUrl}/users/me`

    const user = localStorage.getItem('user')
    if (user !== null) {
      return Promise.resolve(JSON.parse(user))
    }

    return httpClient(url, {
      method: 'GET',
    })
      .then(({ json }) => {
        // munge details to fit react admin
        const details = {
          fullName: json.name,
          ...json,
        }
        localStorage.setItem('user', JSON.stringify(details))
        localStorage.setItem(
          'defaultCurrency',
          details.organisation?.currency?.code || defaultCurrency
        )
        localStorage.setItem(
          'defaultNoun',
          details.organisation?.noun || defaultNoun
        )
        ReactGA.set({ userId: details.id })
        hotjar.identify(details.email, {})
        ReactGA.event({
          category: CATEGORY_USER,
          action: EVENT_LOGGED_IN,
        })
        return Promise.resolve(details)
      })
      .catch(() => {
        return Promise.reject()
      })
  },
  getPermissions: () => {
    const url = `${apiUrl}/users/me`

    const user = localStorage.getItem('user')
    if (user !== null) {
      const u = JSON.parse(user)
      return Promise.resolve(u.permission_flags)
    }

    return httpClient(url, {
      method: 'GET',
    })
      .then(({ json }) => {
        return Promise.resolve(json.permission_flags)
      })
      .catch(() => {
        return Promise.resolve('')
      })
  },
  getAccessToken: () => {
    const token = localStorage.getItem('access_token')
    return Promise.resolve(token)
  },
}

export default authProvider
