import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'

export const Pagination = ({
  goToNextPage,
  goToPreviousPage,
  canPreviousPage,
  canNextPage,
  startIndex,
  endIndex,
  totalItems,
}: {
  startIndex: number
  endIndex: number
  totalItems: number
  goToNextPage: () => void
  goToPreviousPage: () => void
  canPreviousPage: boolean
  canNextPage: boolean
}) => {
  const startIndexNormalized = startIndex + 1 < 0 ? 0 : startIndex + 1
  const endIndexNormalized = endIndex + 1 < 0 ? 0 : endIndex + 1
  const totalItemsNormalized = totalItems < 0 ? 0 : totalItems
  return (
    <div className="flex gap-4 items-center justify-end pt-2 text-sm">
      <div>
        <span className="font-medium">{startIndexNormalized} </span>
        to
        <span className="font-medium"> {endIndexNormalized} </span>
        of
        <span className="font-medium"> {totalItemsNormalized}</span>
      </div>
      <div className="flex items-center justify-between border hover:shadow-sm rounded-lg divide-x overflow-hidden">
        <button
          type="button"
          className="p-2 active hover:bg-slate-50 disabled:opacity-40"
          disabled={!canPreviousPage}
          onClick={() => goToPreviousPage()}
        >
          <ChevronLeftIcon className="h-3.5 w-3.5" />
        </button>
        <button
          type="button"
          className="p-2 active hover:bg-slate-50 disabled:opacity-40"
          disabled={!canNextPage}
          onClick={() => goToNextPage()}
        >
          <ChevronRightIcon className="h-3.5 w-3.5" />
        </button>
      </div>
    </div>
  )
}
