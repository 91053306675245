import { useCallback, useEffect, useState } from 'react'
import { FormControl } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { apiUrl, httpClient } from '../../dataProvider'
import {
  Tag,
  TagCategory,
} from '../../components/customCharts/CustomChartState'

const TagEdit = ({
  tagCategories,
  selectedTag,
  updateTag,
  updateTagCategory,
}: {
  tagCategories: TagCategory[]
  selectedTag: [TagCategory | null, Tag | null]
  updateTag: (tag: Tag | null) => void
  updateTagCategory: (tagCategory: TagCategory | null) => void
}) => {
  const [tagOptions, setTagOptions] = useState<Tag[]>([])
  const selectedTagCategory = selectedTag[0]

  const getTagOptions = useCallback(async () => {
    if (selectedTagCategory) {
      const { json: tags } = await httpClient(
        `${apiUrl}/tags?limit=1000&offset=0&sort_dir=ASC&sort_field=name&tag_category_id=${selectedTagCategory.id}`
      )
      setTagOptions(tags)
    } else {
      setTagOptions([])
    }
  }, [selectedTagCategory])

  useEffect(() => {
    if (selectedTagCategory) {
      getTagOptions()
    }
  }, [getTagOptions, selectedTagCategory])

  return (
    <div style={{ display: 'flex' }}>
      <FormControl fullWidth>
        <Autocomplete
          renderInput={(params) => <TextField {...params} label="Category" />}
          options={tagCategories}
          getOptionLabel={(option) => option.name}
          value={selectedTagCategory}
          onChange={(event, value) => {
            updateTag(null)
            updateTagCategory(value)
          }}
          sx={{
            '& .MuiFormControl-root': { margin: 'unset', paddingRight: '30px' },
          }}
        />
      </FormControl>
      <FormControl fullWidth>
        <Autocomplete
          renderInput={(params) => <TextField {...params} label="Tag" />}
          options={tagOptions}
          getOptionLabel={(option) => option.name}
          value={selectedTag[1]}
          onChange={(event, value) => {
            updateTag(value)
          }}
          disabled={!selectedTagCategory}
          sx={{ '& .MuiFormControl-root': { margin: 'unset' } }}
        />
      </FormControl>
    </div>
  )
}

export default TagEdit
