const FullText = ({
  text,
  className,
}: {
  text: string
  className?: string
}) => (
  <div
    className={`py-3 pr-2 flex items-center space-x-2 text-sm font-semibold z-10 relative ${
      className || ''
    }`}
  >
    <h3 className="text-stone-700 whitespace-nowrap">{text}</h3>
  </div>
)

export default FullText
