import { AddCircle as MuiAddCircle } from '@mui/icons-material'
import { DefaultComponentProps } from '@mui/material/OverridableComponent'
import { SvgIconTypeMap } from '@mui/material'

const AddCircle = (
  props: DefaultComponentProps<SvgIconTypeMap> & { disabled?: boolean }
) => {
  const { className, disabled, ...restProps } = props
  return (
    <MuiAddCircle
      className={`${
        disabled ? 'opacity-50' : 'hover:opacity-80 cursor-pointer'
      } ${className}`}
      htmlColor="#4338CA"
      {...restProps}
    />
  )
}

export default AddCircle
