import { useContext, useMemo, useState } from 'react'
import { DateRangePicker, createStaticRanges } from 'react-date-range'
import { CheckIcon } from '@heroicons/react/24/outline'
import {
  startOfMonth,
  endOfMonth,
  addMonths,
  subYears,
  subMonths,
  subQuarters,
  endOfQuarter,
  differenceInDays,
  subDays,
  startOfYear,
  startOfQuarter,
} from 'date-fns'

import { useTranslation } from 'react-i18next'
import { Modal } from './common/Modal'
import { InvoiceDateFilterSummary } from './InvoiceDateFilterSummary'
import { Button } from './common/Button'
import useChartContext from '../Dashboard/charts/useChartContext'
import {
  CUSTOM_PERIOD,
  LAST_QUARTER_OF_UPLOAD,
  LAST_YEAR,
  PRECEDING_PERIOD,
  SAME_PERIOD_LAST_YEAR,
  THIS_QUARTER,
  THIS_YEAR_TO_DATE,
} from '../constants'
import { DateRangeFilterContext } from '../contexts/DateRangeFilterContext'

export const InvoiceDateFilterModal = ({ compareWithEnabled }): any => {
  const { defaultRange, ranges, setRanges } = useContext(DateRangeFilterContext)

  const { rules } = useChartContext()
  const { t } = useTranslation()
  const invoiceDates = useMemo(
    () => rules.filter((rule) => rule.field === 'invoice_date'),
    [rules]
  )
  const [showModal, setShowModal] = useState(false)
  const [value, setValue] = useState(ranges)

  const rangeDiff = useMemo(() => {
    if (ranges?.length) {
      return differenceInDays(ranges[0].endDate, ranges[0].startDate)
    }
    return 0
  }, [ranges])

  if (!ranges?.length || !defaultRange?.length) {
    return (
      <div className="h-8 w-full bg-stone-200/50 rounded-md animate-pulse" />
    )
  }

  const handleApplyFilter = () => {
    setRanges(value)
    setShowModal(false)
  }

  const handleSetDefault = () => {
    setRanges(defaultRange)
    setShowModal(false)
  }

  const defineds = {
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfLastQuarter: subQuarters(new Date(), 1),
    endOfLastQuarter: endOfQuarter(subQuarters(new Date(), 1)),
    startOfThisQuarter: startOfQuarter(new Date()),
    endOfThisQuarter: endOfQuarter(new Date()),
    startOfLast6Months: subMonths(new Date(), 6),
    endOfLast6Months: new Date(),
    startOfLastYear: subYears(new Date(), 1),
    endOfLastYear: new Date(),
    startOfInvoiceDate: defaultRange[0].startDate,
    endOfInvoiceDate: defaultRange[0].endDate,
  }

  const staticRanges = createStaticRanges([
    {
      label: t('last_month'),
      range: () => ({
        startDate: defineds.startOfLastMonth,
        endDate: defineds.endOfLastMonth,
        key: 'staticRange',
      }),
    },
    {
      label: t('last_quarter'),
      range: () => ({
        startDate: defineds.startOfLastQuarter,
        endDate: defineds.endOfLastQuarter,
        key: 'staticRange',
      }),
    },
    {
      label: t('last_6_months'),
      range: () => ({
        startDate: defineds.startOfLast6Months,
        endDate: defineds.endOfLast6Months,
        key: 'staticRange',
      }),
    },
    {
      label: t('last_year'),
      range: () => ({
        startDate: defineds.startOfLastYear,
        endDate: defineds.endOfLastYear,
        key: 'staticRange',
      }),
    },
    {
      label: t('last_year_upload'),
      range: () => ({
        startDate: defineds.startOfInvoiceDate,
        endDate: defineds.endOfInvoiceDate,
        key: 'staticRange',
      }),
    },
  ])

  const updateRanges = (selected) => {
    if (selected === PRECEDING_PERIOD) {
      setRanges([
        ranges[0],
        {
          startDate: subDays(ranges[0].startDate, rangeDiff),
          endDate: subDays(ranges[0].endDate, rangeDiff),
          key: 'compare',
        },
      ])
    } else if (selected === SAME_PERIOD_LAST_YEAR) {
      setRanges([
        ranges[0],
        {
          startDate: subYears(ranges[0].startDate, 1),
          endDate: subYears(ranges[0].endDate, 1),
          key: 'compare',
        },
      ])
    } else if (selected === THIS_YEAR_TO_DATE) {
      setRanges([
        ranges[0],
        {
          startDate: startOfYear(new Date()),
          endDate: new Date(),
          key: 'compare',
        },
      ])
    } else if (selected === LAST_YEAR) {
      setRanges([
        ranges[0],
        {
          startDate: defineds.startOfLastYear,
          endDate: defineds.endOfLastYear,
          key: 'compare',
        },
      ])
    } else if (selected === THIS_QUARTER) {
      setRanges([
        ranges[0],
        {
          startDate: defineds.startOfThisQuarter,
          endDate: defineds.endOfThisQuarter,
          key: 'compare',
        },
      ])
    } else if (selected === LAST_QUARTER_OF_UPLOAD) {
      setRanges([
        ranges[0],
        {
          startDate: defineds.startOfLastQuarter,
          endDate: defineds.endOfLastQuarter,
          key: 'compare',
        },
      ])
    } else if (selected === CUSTOM_PERIOD) {
      setRanges([
        {
          startDate: startOfMonth(new Date()),
          endDate: endOfMonth(new Date()),
          key: 'selection',
        },
        {
          startDate: defineds.startOfLastMonth,
          endDate: defineds.endOfLastMonth,
          key: 'compare',
        },
      ])
    } else {
      setRanges([ranges[0]])
    }
  }

  return invoiceDates.length === 0 ? (
    <Modal
      isOpen={showModal}
      onClose={() => setShowModal(false)}
      onOpen={() => setShowModal(true)}
      renderButton={(onClick) => {
        const onCompareClick = (selected: string) => {
          updateRanges(selected)
          if (selected === CUSTOM_PERIOD) {
            onClick()
          }
        }
        return (
          <InvoiceDateFilterSummary
            {...ranges[0]}
            onClick={onClick}
            onCompareClick={onCompareClick}
            compareWithEnabled={compareWithEnabled}
          />
        )
      }}
    >
      <div className="min-w-[650px] bg-white border rounded-lg divide-y overflow-hidden">
        <div className="p-3 space-y-4">
          <DateRangePicker
            onChange={(data) => {
              const key = Object.keys(data)[0]
              if (key === 'selection') {
                setValue([data.selection])
              } else if (key === 'staticRange') {
                setValue([data.staticRange])
              } else if (key === 'range1') {
                setValue([data.range1])
              } else {
                setValue([data.staticRange.startDate, data.staticRange.endDate])
              }
            }}
            months={1}
            staticRanges={staticRanges}
            ranges={value}
            scroll={{ enabled: true }}
            direction="vertical"
          />
          <div className="flex items-center space-x-3 justify-end flex-1">
            <Button onClick={handleSetDefault}>{t('set_default')}</Button>
            <Button
              disabled={!ranges}
              icon={<CheckIcon className="h-4" />}
              onClick={handleApplyFilter}
            >
              {t('update_filter')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  ) : null
}
