import { useQuery } from 'react-query'
import { useDataProvider } from 'react-admin'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NoData } from './NoData'
import { SectionTitle } from '../components/Title'
import { VendorGroupsTable } from './tables/VendorGroupsTable'
import useChartContext from './charts/useChartContext'
import { GROUP_ID, VENDOR_TAG_ID } from '../constants'
import TopVendorsChart from './charts/TopVendorsChart'
import { formatCurrency, getLabelSuffix } from '../helpers'
import { VendorChildTable } from './tables/VendorChildTable'

export const VendorGroupSection = () => {
  const [selectedLevel, setSelectedLevel] = useState(1)
  const dp = useDataProvider()
  const { query, setQuery, rules, addRule, dateRange } = useChartContext()
  const { t } = useTranslation()
  const suffix = getLabelSuffix()
  const [currentPage, setCurrentPage] = useState(1)
  const ITEMS_PER_PAGE = 10 // Adjust as necessary

  const params: any = {
    filter: {
      query,
      ...dateRange,
    },
    sort: { field: 'total_spend', order: 'desc' },
    pagination: { page: currentPage, perPage: ITEMS_PER_PAGE },
  }

  const { data, isLoading } = useQuery(
    ['groups', currentPage, query, dateRange],
    () => dp.getList('groups', params)
  )

  const [childData, setChildData] = useState([])

  const { data: vendorFilterData } = useQuery(
    ['charts', 'getGetChart', 'spending', query, dateRange],
    () => dp.getChart('topVendorsSum', { query, ...dateRange })
  )

  const totalPages = useMemo(() => {
    if (isLoading || !data) return 0
    if (data.total) {
      return Math.ceil(data.total / ITEMS_PER_PAGE)
    }
    return 0
  }, [data, isLoading])

  const formattedParentData = useMemo(() => {
    if (isLoading || !data) return []

    return data.data
      .filter((c) => c.total_spend > 0)
      .map((v) => ({
        id: v.id,
        label: v.name,
        value: v.total_spend / 100,
        formattedValue: formatCurrency(v.total_spend / 100),
        country: 'GBR',
      }))
  }, [data, isLoading])

  const formattedChildData = useMemo(() => {
    if (isLoading || !data) return []

    return childData
      .map((v: any) => ({
        id: v.id,
        label: v.name,
        value: v.total_spend / 100,
        formattedValue: formatCurrency(v.total_spend / 100),
        country: 'GBR',
      }))
      .sort((a, b) => {
        if (a > b) return 1
        if (a < b) return -1
        return 0
      })
  }, [data, childData, isLoading])

  const vendorFilterExists = useMemo(() => {
    return rules.find((rule) => rule.field === `vendor_id`)
  }, [rules])

  const groupFilterExists = useMemo(() => {
    return rules.find((rule) => rule.field === `group_id`)
  }, [rules])

  useEffect(() => {
    if (!groupFilterExists && selectedLevel === 2) {
      setSelectedLevel(1)
    }
  }, [groupFilterExists, selectedLevel])

  const replaceRule = (RULE_NAME, newValue) => {
    if (groupFilterExists) {
      const newRules = rules.map((rule) => {
        if (rule.field === RULE_NAME) {
          rule.value = [newValue]
        }
        return rule
      })
      const parsedQuery = JSON.parse(query)
      const newQuery = JSON.stringify({ ...parsedQuery, rules: newRules })
      setQuery(newQuery)
    } else {
      addRule(RULE_NAME, [newValue])
    }
  }
  const appendRule = (RULE_NAME, addValue) => {
    if (vendorFilterExists) {
      const newRules = rules.map((rule) => {
        if (rule.field === RULE_NAME) {
          if (!rule.value.includes(addValue)) {
            rule.value = [...rule.value, addValue]
          } else {
            rule.value = rule.value.filter((r: number) => r !== addValue)
          }
        }
        return rule
      })
      const parsedQuery = JSON.parse(query)
      const newQuery = JSON.stringify({ ...parsedQuery, rules: newRules })
      setQuery(newQuery)
    } else {
      addRule(RULE_NAME, [addValue])
    }
  }

  if ((!data || !data.data.length) && !isLoading) {
    return (
      <div className="h-[300px] flex items-center justify-center col-span-4">
        <NoData />
      </div>
    )
  }

  const groupToggleClass = `px-3 py-2.5 rounded transition-all font-semibold active
          ${
            selectedLevel === 1
              ? 'bg-white'
              : 'text-stone-500 hover:text-stone-700 hover:bg-white/70'
          }
          `
  const vendorToggleClass = `px-3 py-2.5 rounded transition-all font-semibold active
          ${
            selectedLevel === 2
              ? 'bg-white'
              : `text-stone-500 ${
                  groupFilterExists
                    ? 'hover:text-stone-700 hover:bg-white/70 pointer'
                    : ''
                }`
          }
          `

  return (
    <div>
      <div className="flex items-center justify-between mb-6">
        <SectionTitle
          title={t(`home.chart_title_8${suffix}`)}
          subtitle={t('home.chart_desc_8')}
        />
        <div className="flex bg-stone-100 border items-center space-x-1 text-sm p-1 rounded-md">
          <button
            // eslint-disable-next-line react/no-array-index-key
            type="button"
            onClick={() => {
              setSelectedLevel(1)
            }}
            className={groupToggleClass}
          >
            {t('groups')}
          </button>
          <button
            // eslint-disable-next-line react/no-array-index-key
            type="button"
            onClick={() => {
              if (groupFilterExists) {
                setSelectedLevel(2)
              }
            }}
            disabled={!groupFilterExists}
            className={vendorToggleClass}
          >
            {t(`vendors${suffix}`)}
          </button>
        </div>
      </div>

      {selectedLevel === 1 ? (
        <div className="grid grid-cols-2 gap-x-4">
          <VendorGroupsTable
            t={t}
            groupFilters={groupFilterExists ? groupFilterExists.value : []}
            suffix={suffix}
            onClick={(id, children) => {
              setSelectedLevel(2)
              setChildData(children)
              replaceRule(GROUP_ID, id)
            }}
            isLoading={isLoading || false}
            data={data?.data || []}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            itemsPerPage={ITEMS_PER_PAGE}
            totalItems={data?.total}
          />
          <TopVendorsChart
            data={formattedParentData}
            showTotalSpend
            isLoading={isLoading}
          />
        </div>
      ) : (
        <div className="grid grid-cols-2 gap-x-4">
          <VendorChildTable
            vendorFilterData={vendorFilterData ? vendorFilterData.data : []}
            onClick={(id) => {
              appendRule(VENDOR_TAG_ID, id)
            }}
            data={childData || []}
          />
          <TopVendorsChart
            data={formattedChildData}
            showTotalSpend
            isLoading={isLoading}
          />
        </div>
      )}
    </div>
  )
}
