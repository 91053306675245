import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useDataProvider, useListContext, useRefresh } from 'react-admin'
import { t } from 'i18next'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '../../components/Dialog'
import { getLabelSuffix } from '../../helpers'
import { Button } from '../../components/common/Button'
import { TagCategory } from '../../components/customCharts/CustomChartState'
import TransactionListBulkEditContent from './TransactionListBulkEditContent'
import LinearProgress from '../../components/Loaders/LinearProgress'
import { SelectedTags } from './TransactionListBulkEdit.types'
import { Transaction } from '../TransactionList.types'
import { bulkUpdate } from './TransactionListBulkEdit.utils'

const allTagSelectionsPopulated = (selectedTags: SelectedTags) =>
  selectedTags.every((selection) => !!selection[0] && !!selection[1])

const TransactionListBulkEdit = ({
  open,
  onClose,
  initialQuery,
}: {
  open: boolean
  onClose: Dispatch<SetStateAction<boolean>>
  initialQuery
}) => {
  const dp = useDataProvider()
  const refresh = useRefresh()
  const list = useListContext<Transaction>()
  const suffix = getLabelSuffix()
  const [tagCategories, setTagCategories] = useState<TagCategory[]>([])
  const [selectedTags, setSelectedTags] = useState<SelectedTags>([[null, null]])
  const [submitting, setSubmitting] = useState(false)
  const [pageProgress, setPageProgress] = useState(0)

  const fetchTags = useCallback(async () => {
    const tagCs = await dp.getTagCategories()
    setTagCategories(tagCs)
  }, [dp])

  useEffect(() => {
    fetchTags()
  }, [fetchTags])

  const updateAllTransactions = async () => {
    setSubmitting(true)
    await bulkUpdate(selectedTags, list, initialQuery, dp, setPageProgress)
    refresh()
    setSubmitting(false)
    onClose(false)
  }

  const numberOfTransactions = list.selectedIds?.length
    ? list.selectedIds.length
    : list.total

  const percentageProgress = ~~Math.min(
    ((pageProgress * list.perPage) / numberOfTransactions) * 100,
    100
  )

  return (
    <>
      <Dialog
        open={open}
        onClose={() => onClose(false)}
        maxWidth="md"
        fullWidth
        sx={{
          '& .MuiDialog-paper': { height: '50%' },
        }}
      >
        <DialogTitle>{t(`bulk_edit_transactions${suffix}`)}</DialogTitle>
        <TransactionListBulkEditContent
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          tagCategories={tagCategories}
          totalTransactionNumber={numberOfTransactions}
        />
        <DialogActions>
          <Button secondary onClick={() => onClose(false)}>
            Cancel
          </Button>
          <Button
            disabled={!allTagSelectionsPopulated(selectedTags)}
            onClick={updateAllTransactions}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={submitting}>
        <DialogContent sx={{ width: '400px' }}>
          <div>
            Updating {numberOfTransactions} transaction
            {numberOfTransactions > 1 ? 's' : ''}...
          </div>
          <div style={{ margin: '12px 0 36px' }}>
            <LinearProgress value={percentageProgress} />
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default TransactionListBulkEdit
