import { stringify } from 'query-string'
import { fetchUtils } from 'ra-core'
import { addDateRangeToQuery } from './helpers/index'
import authProvider from './authProvider'
import { ops, vendorOps } from './Transactions/TransactionList'

export const apiUrl = process.env.REACT_APP_ADMIN_API

export const httpClient = (url, options = {}) => {
  const noptions = options

  const actingAs = JSON.parse(
    localStorage.getItem('RaStore.organisation.actingAs')
  )

  if (actingAs && actingAs.organisationId !== 0) {
    const addr = new URL(url)
    const params = addr.searchParams

    params.delete('organisation_id')
    params.set('organisation_id', actingAs.organisationId)

    url = addr.toString()
  }

  if (!noptions.headers) {
    noptions.headers = new Headers({ Accept: 'application/json' })
  }

  return authProvider.getAccessToken().then((token) => {
    if (token) {
      noptions.headers.set('Authorization', `Bearer ${token}`)
    }
    return fetchUtils.fetchJson(url, noptions)
  })
}

const fileHttpClient = (url, options = {}) => {
  const noptions = options

  if (!noptions.headers) {
    noptions.headers = new Headers()
  }
  return authProvider.getAccessToken().then((token) => {
    if (token) {
      noptions.headers.set('Authorization', `Bearer ${token}`)
    }
    return fetchUtils.fetchJson(url, noptions)
  })
}

const dataProvider = {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort
    const query = {
      sort_field: field,
      sort_dir: order,
      offset: JSON.stringify((page - 1) * perPage),
      limit: JSON.stringify(perPage),
      ...params.filter,
    }
    if (params.filter && params.filter.startDate && params.filter.endDate) {
      const newQuery = addDateRangeToQuery(
        params.filter.query,
        params.filter.startDate,
        params.filter.endDate
      )
      query.query = JSON.stringify(newQuery)
    }

    const url = `${apiUrl}/${resource}?${stringify(query)}`

    return httpClient(url).then(({ headers, json }) => {
      return {
        data: json,
        total: parseInt(headers.get('content-range').split('/').pop(), 10),
      }
    })
  },

  getOne: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`).then(
      ({ json }) => ({
        data: json,
      })
    )
  },

  getMany: (resource, params) => {
    const query = {
      ...params,
    }

    if (typeof params.filter !== 'undefined' && params.filter.length > 0) {
      query.ids = JSON.stringify(params.filter)
    }

    const url = `${apiUrl}/${resource}?${stringify(query)}`
    return httpClient(url).then(({ json }) => ({ data: json }))
  },

  getManyReference: (resource, oparams) => {
    const params = oparams

    const { page, perPage } = params.pagination
    const { field, order } = params.sort

    if (typeof params.target !== 'undefined') {
      params.filter[params.target] = params.id
    }

    const query = {
      sort_field: field,
      sort_dir: order,
      offset: JSON.stringify((page - 1) * perPage),
      limit: JSON.stringify(perPage),
      ...params.filter,
    }

    const url = `${apiUrl}/${resource}?${stringify(query)}`

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get('content-range').split('/').pop(), 10),
    }))
  },

  update: (resource, params) => {
    if (resource === 'vendors_tags') {
      resource = 'vendors/tags'
    }

    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }))
  },

  getProfile: () => {
    return httpClient(`${apiUrl}/users/me`, {
      method: 'GET',
    }).then(({ json }) => ({ data: json }))
  },

  updateProfile: (params) => {
    return httpClient(`${apiUrl}/users/me`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }))
  },

  updateMany: (resource, params) => {
    const query = {}

    if (params.filter.length > 0) {
      query.ids = JSON.stringify(params.filter)
    }

    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }))
  },

  create: (resource, params) => {
    let url = `${apiUrl}/${resource}`

    if (
      resource === 'import_logs' &&
      params.data.file &&
      params.data.file.rawFile
    ) {
      const formData = new FormData()
      formData.append('file', params.data.file.rawFile)

      return fileHttpClient(url, {
        method: 'POST',
        body: formData,
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }))
    }
    if (resource === 'import_logs' && !params.data.file) {
      resource = 'import_logs_manual'
      url = `${apiUrl}/${resource}`
    }

    return httpClient(url, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }))
  },

  delete: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json }))
  },
  deleteMany: (resource, params) => {
    const query = {}

    if (params.ids.length > 0) {
      query.ids = params.ids.join(',')
    }

    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json }))
  },

  uploadFile: (resource, params) => {
    const formData = new FormData()

    formData.append('csv', params.import_file.rawFile)

    const actingAs = JSON.parse(
      localStorage.getItem('RaStore.organisation.actingAs')
    )
    if (actingAs && actingAs.organisationId !== 0) {
      params.organisation_id = actingAs.organisationId
    }

    formData.append('organisation_id', params.organisation_id)

    return httpClient(`${apiUrl}/${resource}/upload`, {
      method: 'POST',
      body: formData,
    }).then(({ json }) => ({ data: json.filename, message: json.message }))
  },

  transactionChangeVendor: (params) => {
    return httpClient(`${apiUrl}/transactions/changeVendor/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }))
  },

  assignGroup: (params) => {
    return httpClient(`${apiUrl}/assign-group`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }))
  },

  downloadFileImport: (id) => {
    return httpClient(`${apiUrl}/import_logs/${id}/download`, {
      method: 'POST',
    }).then(({ json }) => ({ data: json }))
  },

  startFileImport: (id) => {
    return httpClient(`${apiUrl}/import_logs/${id}/start_process`, {
      method: 'POST',
    }).then(({ json }) => ({ data: json }))
  },

  addReferenceVendor: (params) => {
    return httpClient(`${apiUrl}/referenceVendorMapper`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }))
  },

  importDemoData: () => {
    return httpClient(`${apiUrl}/organisations/import/demo`, {
      method: 'GET',
    }).then(({ json }) => ({ data: json }))
  },

  getDemoCreditScore: () => {
    return httpClient(`${apiUrl}/organisations/import/credit`, {
      method: 'GET',
    }).then(({ json }) => ({ data: json }))
  },

  transactionConfirmVendor: (params) => {
    const query = {
      organisation_id: params.organisation_id,
    }

    if (params.ids.length > 0) {
      query.ids = params.ids
    }

    return httpClient(
      `${apiUrl}/transactions/confirmVendor/?${stringify(query)}`,
      {
        method: 'PUT',
        body: JSON.stringify(params.data),
      }
    ).then(({ json }) => ({ data: json }))
  },

  getDateRange: async () => {
    const url = `${apiUrl}/charts/dateRange`

    const { json } = await httpClient(url)

    return { data: json }
  },

  getFeatures: async () => {
    const url = `${apiUrl}/features`

    const { json } = await httpClient(url)

    return { data: json }
  },
  getChart: async (chartType, params) => {
    if (params.startDate && params.endDate) {
      const newQuery = addDateRangeToQuery(
        params.query,
        params.startDate,
        params.endDate
      )
      params.query = JSON.stringify(newQuery)
    }
    const query = {
      ...params,
    }

    const url = `${apiUrl}/charts/${chartType}?${stringify(query)}`

    let { json } = await httpClient(url)
    if (chartType === 'country') {
      json = json.map((item) => {
        item.value /= 100
        return item
      })
    }
    return { data: json }
  },

  getLevels: () => {
    const url = `${apiUrl}/tag_categories/levels`

    return httpClient(url).then(({ json }) => ({
      data: json,
    }))
  },

  getPriceTrackingTags: (params) => {
    const query = {
      ...params.filter,
    }
    const url = `${apiUrl}/priceTrackingTags?${stringify(query)}`

    return httpClient(url).then(({ json }) => ({
      data: json,
    }))
  },

  getTagCategories: async () => {
    const url = `${apiUrl}/tag_categories?sort_field=name`
    const { json } = await httpClient(url)
    return json
  },

  getTagsForCategory: async (tagCategoryId) => {
    const url = `${apiUrl}/tags?limit=1000&offset=0&sort_dir=ASC&sort_field=name&tag_category_id=${tagCategoryId}`
    const { json } = await httpClient(url)
    return json
  },

  getFilterFields: () => {
    const url = `${apiUrl}/tag_categories?sort_field=name`
    return httpClient(url).then(({ json }) => {
      json.map((tc) => {
        tc.label = tc.name
        tc.name = `tag_id[${tc.id}]`
        tc.operators = ops
        tc.datatype = `tag${tc.id}`
        tc.id = `tag_id_${tc.id}`
        return tc
      })
      json.push({
        label: 'Vendor',
        name: 'vendor_id',
        id: 'vendor_id',
        operators: vendorOps,
        datatype: 'vendor',
      })
      json.push({
        label: 'Group',
        name: 'group_id',
        id: 'group_id',
        operators: vendorOps,
        datatype: 'group',
      })
      // json.push({
      //   label: 'Country',
      //   name: 'territory_id',
      //   id: 'territory_id',
      //   operators: ops,
      //   datatype: 'territory',
      // })

      // json.push({
      //   label: 'Invoice Date',
      //   name: 'invoice_date',
      //   operators: [{ name: 'between', label: 'is between' }],
      //   datatype: 'dateRange',
      // })
      json.sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
      return { data: json }
    })
  },

  getCSVRowCount: (params) => {
    const query = {
      ...params,
    }

    const url = `${apiUrl}/exports/csv_row_count?${stringify(query)}`

    return httpClient(url).then(({ json }) => ({
      data: json,
    }))
  },

  getCSVUrl: (params, token) => {
    const query = {
      token,
      ...params,
    }

    const url = `${apiUrl}/exports/csv?${stringify(query)}`

    return new Promise((resolve) => {
      resolve(url)
    })
  },
  forgottenPassword: (params) => {
    return httpClient(`${apiUrl}/auth/forgot-password`, {
      method: 'POST',
      body: JSON.stringify(params),
    }).then(({ json }) => ({ data: json }))
  },
  resetPassword: (params) => {
    // this method is used for forgotten passwords
    return httpClient(`${apiUrl}/auth/reset-password`, {
      method: 'POST',
      body: JSON.stringify(params),
    }).then(({ json }) => ({ data: json }))
  },
  updatePassword: (id, params) => {
    // this method is used for a logged-in user
    return httpClient(`${apiUrl}/users/password/${id}`, {
      method: 'PATCH',
      body: JSON.stringify(params),
    }).then(({ json }) => ({ data: json }))
  },

  /*
    Get the details of a widget
   */
  getWidget: (id) => {
    return httpClient(`${apiUrl}/widget/${id}`).then(({ json }) => {
      return json
    })
  },
  getWidgets: async () => {
    return httpClient(`${apiUrl}/widgets`).then(({ json }) => {
      return json
    })
  },
  getUserWidgets: async () => {
    return httpClient(`${apiUrl}/user-widgets`).then(({ json }) => {
      return json
    })
  },
  setUserWidgets: (widgets) => {
    return httpClient(`${apiUrl}/user-widgets`, {
      method: 'POST',
      body: JSON.stringify(widgets),
    }).then(({ json }) => {
      console.log('setUserWidgets', json)
    })
  },
  getUserAvailableWidgets: async () => {
    return httpClient(`${apiUrl}/user-available-widgets`).then(({ json }) => {
      return json
    })
  },
  /*
   add a new widget
   name: new name for the widget
   description: new description for the widget
   is_available: if set to true it will show as available for the user / system
   is_custom: if is_custom, only this user can see it as available
   custom_value: JSON representation of stored values needed by the APP to request data from the backend
                 and display it how the user wants
  */
  addWidget: (name, description, isAvailable, isCustom, customValue) => {
    return httpClient(`${apiUrl}/widget`, {
      method: 'POST',
      body: JSON.stringify({
        name,
        description,
        is_available: isAvailable,
        is_custom: isCustom,
        custom_value: JSON.stringify(customValue),
      }),
    }).then(({ json }) => {
      return json
    })
  },
  /*
   update a currently existing widget
   can be used to update a custom widget for a user or to update the system widgets
   id: widget ID to update
   name: new name for the widget
   description: new description for the widget
   is_available: if set to true it will show as available for the user / system
   is_custom: if is_custom, only this user can see it as available
   custom_value: JSON representation of stored values needed by the APP to request data from the backend
                 and display it how the user wants
   */
  updateWidget: (id, name, description, isAvailable, isCustom, customValue) => {
    return httpClient(`${apiUrl}/widget`, {
      method: 'PUT',
      body: JSON.stringify({
        id,
        name,
        description,
        is_available: isAvailable,
        is_custom: isCustom,
        custom_value: JSON.stringify(customValue),
      }),
    }).then(({ json }) => {
      return json
    })
  },
  // completely delete a widget from the system
  removeWidget: (id) => {
    return httpClient(`${apiUrl}/widget/${id}`, {
      method: 'DELETE',
    }).then(({ json }) => {
      return json
    })
  },

  getPriceDashboard: async (tagId) => {
    const url = `${apiUrl}/priceDashboard?tag_id=${tagId}`

    const { json } = await httpClient(url)

    return { data: json }
  },
}

export default dataProvider
