import { Link, useCreatePath, useDataProvider } from 'react-admin'
import { useQuery } from 'react-query'
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'

import {
  ChartBarIcon as ChartBarIconSolid,
  ChartPieIcon as ChartPieIconSolid,
} from '@heroicons/react/24/solid'
import { ChartBarIcon, ChartPieIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import useChartContext from './charts/useChartContext'
import {
  COLOR_PALETTE,
  COLOR_PALETTE_LEVEL_2,
  COLOR_PALETTE_LEVEL_3,
  COLOR_PALETTE_MAP,
} from './colors'
import { NoData } from './NoData'
import { CategoryChart, CategoryChartLoading } from './charts/CategoryChart'
import { CategoryTable, CategoryTableLoading } from './tables/CategoryTable'
import { SectionTitle } from '../components/Title'
import { getLabelSuffix } from '../helpers'

const chartType = 'tagBreakdownSum'

const LevelOne = 'Level 1'
const LevelTwo = 'Level 2'
const LevelThree = 'Level 3'
const LevelFour = 'Level 4'

const defaultState = {
  LevelOne: 0,
  LevelTwo: 0,
  LevelThree: 0,
  LevelFour: 0,
}

export const CategorySection = () => {
  const { dateRange, query, rules, addRule, removeRules, clearFilter } =
    useChartContext()
  const dp = useDataProvider()
  const createPath = useCreatePath()
  const { t, i18n } = useTranslation()
  const suffix = getLabelSuffix()

  const [lastTagApplied, setLastTagApplied] = useState<number | null>(null)
  const [selectedLevel, setSelectedLevel] = useState(1)
  const [showWaterfallChart, setShowWaterfallChart] = useState(false)
  const [highlightedColor, setHighlightedColor] = useState<string>('')

  const [levels, setLevels] = useState(defaultState)

  const getTagCategoryId = useCallback(
    (level: number) => {
      if (level === 2) return levels[LevelTwo]
      if (level === 3) return levels[LevelThree]
      if (level === 4) return levels[LevelFour]
      return levels[LevelOne]
    },
    [levels]
  )
  const finalLevelKey = Object.keys(levels)[Object.keys(levels).length - 1]

  const tagCategoryId = useMemo(
    () => getTagCategoryId(selectedLevel),
    [getTagCategoryId, selectedLevel]
  )

  const { data, isLoading } = useQuery(
    ['charts', 'getGetChart', chartType, tagCategoryId, query, dateRange],
    () =>
      dp.getChart(chartType, {
        query,
        tag_category_id: tagCategoryId,
        ...dateRange,
      }),
    {
      enabled: !!tagCategoryId, // The query will not execute until tagCategoryId is truthy
    }
  )

  const onAddRule = useCallback(
    (tagId: number) => {
      if (lastTagApplied !== levels[finalLevelKey]) {
        addRule(`tag_id[${tagCategoryId}]`, [tagId])
        setLastTagApplied(tagCategoryId)
      }
    },
    [addRule, finalLevelKey, lastTagApplied, levels, tagCategoryId]
  )

  const onSelectLevel = useCallback(
    (level: number) => {
      if (level < selectedLevel) {
        const indexes: number[] = []
        for (let i = level; i <= Object.keys(levels).length; i += 1) {
          const levelTagCategoryId = getTagCategoryId(i)
          const levelTagId = `tag_id[${levelTagCategoryId}]`
          for (let x = 0; x < rules.length; x += 1) {
            if (rules[x].field === levelTagId) {
              indexes.push(x)
            }
          }
        }
        if (indexes.length) {
          removeRules(indexes)
        }

        if (indexes.length === rules.length) {
          setLastTagApplied(null)
        } else {
          const previouslySelectedTag = Object.keys(levels).reduce(
            (acc: null | number, levelKey) => {
              const levelRule = rules.find(
                (rule) => rule.field === `tag_id[${levels[levelKey]}]`
              )
              if (levelRule) {
                return levels[levelKey]
              }
              return acc
            },
            null
          )
          setLastTagApplied(previouslySelectedTag)
        }
      }

      setSelectedLevel(level)
    },
    [getTagCategoryId, levels, removeRules, rules, selectedLevel]
  )

  // eslint-disable-next-line
  const tags = useMemo(() => {
    if (!data || !data.data) return []
    return data.data.map((v) => {
      const localized =
        v.tag?.translations?.find((tr) => tr.language === i18n.language)
          ?.name || v.tag.name === 'everything_else'
          ? t(v.tag.name)
          : v.tag.name
      return {
        id: localized,
        label: localized,
        name: localized,
        value: v.amount / 100,
        tagId: v.tag.id,
      }
    })
    // .sort((a, b) => b.value - a.value)
  }, [data, i18n.language, t])

  const total = useMemo(
    () => tags.reduce((prev, curr) => prev + curr.value, 0),
    [tags]
  )

  const transactionsUrl = createPath({ resource: 'transactions', type: 'list' })

  useLayoutEffect(() => {
    if (!rules.length && selectedLevel !== 1 && lastTagApplied !== null) {
      setSelectedLevel(1)
      setLastTagApplied(null)
    }
  }, [clearFilter, lastTagApplied, rules, selectedLevel])

  useEffect(() => {
    if (
      rules.find((rule) => rule.field === `tag_id[${tagCategoryId}]`) &&
      selectedLevel < Object.keys(levels).length
    ) {
      setSelectedLevel(selectedLevel + 1)
      setHighlightedColor('')
    }
  }, [levels, rules, selectedLevel, tagCategoryId])

  useEffect(() => {
    dp.getLevels().then((levelData) => {
      setLevels(levelData.data)
    })
  }, [dp])

  const colorsByLevel = useMemo(() => {
    switch (selectedLevel) {
      case 2:
        return COLOR_PALETTE_LEVEL_2
      case 3:
        return COLOR_PALETTE_LEVEL_3
      case 4:
        return COLOR_PALETTE_MAP
      default:
        return COLOR_PALETTE
    }
  }, [selectedLevel])

  const colors = useMemo(() => {
    if (!highlightedColor) return colorsByLevel
    // Adds transparency to the color, making the selected one stand out
    return colorsByLevel.map((color) =>
      color !== highlightedColor ? `${color}66` : color
    )
  }, [highlightedColor, colorsByLevel])

  if ((!data || !data.data.length) && !isLoading) {
    return (
      <>
        <SectionTitle
          title={t('home.chart_title_2')}
          subtitle={t('home.chart_desc_2')}
        />
        <div className="h-[300px] flex items-center justify-center col-span-4">
          <NoData />
        </div>
      </>
    )
  }

  return (
    <div>
      <div className="flex items-center justify-between mb-6">
        <SectionTitle
          title={t('home.chart_title_2')}
          subtitle={t('home.chart_desc_2')}
        />
        <div className="flex bg-stone-100 border items-center space-x-1 text-sm p-1 rounded-md">
          {new Array(Object.keys(levels).length).fill('').map((_, i) => (
            <button
              // eslint-disable-next-line react/no-array-index-key
              key={i + 1}
              type="button"
              onClick={() => onSelectLevel(i + 1)}
              className={`px-3 py-2.5 rounded transition-all font-semibold active
          ${
            selectedLevel === i + 1
              ? 'bg-white'
              : 'text-stone-500 hover:text-stone-700 hover:bg-white/70'
          }
          `}
            >
              {t(`level_${i + 1}`)}
            </button>
          ))}
        </div>
      </div>
      <div className="flex divide-x border-t divide-stone-200/50 border-stone-200/50 -mx-6 -mb-6 px-6">
        <div className="py-6 h-full flex-1">
          {isLoading ? (
            <CategoryTableLoading />
          ) : (
            <CategoryTable
              onVendorHighlight={setHighlightedColor}
              total={total}
              tags={tags}
              colors={colors}
              onClick={(tagId) => onAddRule(tagId)}
              canClick={lastTagApplied !== levels[finalLevelKey]}
            />
          )}
        </div>
        <div className="flex flex-col justify-between h-full space-y-8 py-6 pl-6 items-center flex-1">
          <div className="flex space-x-1 items-center justify-center">
            <button
              onClick={() => setShowWaterfallChart(false)}
              type="button"
              className={`p-2 rounded-md active ${
                !showWaterfallChart
                  ? 'bg-stone-200/50'
                  : 'text-stone-600 hover:bg-stone-50'
              }`}
            >
              {showWaterfallChart ? (
                <ChartPieIcon className="h-4" />
              ) : (
                <ChartPieIconSolid className="h-4" />
              )}
            </button>
            <button
              onClick={() => setShowWaterfallChart(true)}
              type="button"
              className={`p-2 rounded-md active ${
                showWaterfallChart
                  ? 'bg-stone-200/50'
                  : 'text-stone-600 hover:bg-stone-50'
              }`}
            >
              {showWaterfallChart ? (
                <ChartBarIconSolid className="h-4" />
              ) : (
                <ChartBarIcon className="h-4" />
              )}
            </button>
          </div>
          <div className="h-[375px] relative w-full">
            {isLoading ? (
              <CategoryChartLoading square={showWaterfallChart} />
            ) : (
              <CategoryChart
                chartType={showWaterfallChart ? 'waterfall' : 'donut'}
                colors={colors}
                addRule={onAddRule}
                tags={tags}
                total={total}
              />
            )}
          </div>
          <div className="flex justify-center">
            <Link
              to={transactionsUrl}
              className="text-sm font-medium px-4 py-3 rounded-md bg-slate-100 hover:bg-slate-100/70 active"
            >
              <span className="text-sky-600">
                {t(`see_transactions${suffix}`)}
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
