import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react'

import { subYears } from 'date-fns'
import { apiUrl, httpClient } from '../dataProvider'

type DateRange = {
  startDate: Date
  endDate: Date
  key: string
}

type TDateRangeFilterContext = {
  defaultRange: DateRange[] | null
  ranges: DateRange[] | null
  setRanges: Dispatch<SetStateAction<DateRange[] | null>>
  fetchUserDateRange: () => Promise<void>
}

export const DateRangeFilterContext = createContext<TDateRangeFilterContext>({
  ranges: null,
  defaultRange: null,
  setRanges: () => {},
  fetchUserDateRange: () => Promise.resolve(),
})

export const DateRangeFilterProvider = ({ children }) => {
  const [ranges, setRanges] = useState<TDateRangeFilterContext['ranges']>(null)
  const [defaultRange, setDefaultRange] =
    useState<TDateRangeFilterContext['defaultRange']>(null)

  const fetchUserDateRange = useCallback(async () => {
    const { json: data } = await httpClient(`${apiUrl}/charts/dateRange`)
    // getDateRange returns the complete date range for the data
    // we want to set the current range (and, by reference, the default range) to the last year of data
    const startDateRange = data.startDateFilter
      ? new Date(data.startDateFilter)
      : subYears(new Date(data.endDateInvoice), 1)
    const endDateRange = data.endDateFilter
      ? new Date(data.endDateFilter)
      : new Date(data.endDateInvoice)
    const startDateDefault = subYears(new Date(data.endDateInvoice), 1)
    const endDateDefault = new Date(data.endDateInvoice)

    setRanges([
      {
        startDate: startDateRange,
        endDate: endDateRange,
        key: 'selection',
      },
    ])
    setDefaultRange([
      {
        startDate: startDateDefault,
        endDate: endDateDefault,
        key: 'selection',
      },
    ])
  }, [])

  const providerValues = useMemo(
    () => ({
      fetchUserDateRange,
      defaultRange,
      ranges,
      setRanges,
    }),
    [defaultRange, ranges, fetchUserDateRange]
  )

  return (
    <DateRangeFilterContext.Provider value={providerValues}>
      {children}
    </DateRangeFilterContext.Provider>
  )
}
