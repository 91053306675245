import { useQuery } from 'react-query'
import { useDataProvider } from 'react-admin'
import { FC, useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import useChartContext from './charts/useChartContext'
import { Card } from '../components/Card'
import TopVendorsChart from './charts/TopVendorsChart'
import { TopVendorsTable } from './tables/TopVendorsTable'
import { formatCurrency, getLabelSuffix } from '../helpers'
import { NoData } from './NoData'

const VENDOR_TAG_ID = 'vendor_id'

interface TopVendorsProps {
  dataType: 'spending' | 'transactions'
}

export const TopVendors: FC<TopVendorsProps> = ({ dataType }) => {
  const showTotalSpend = useMemo(() => dataType === 'spending', [dataType])
  const { query, addRule, dateRange } = useChartContext()
  const dp = useDataProvider()
  const suffix = getLabelSuffix()
  const { t } = useTranslation()
  const chartType = useMemo(
    () => (showTotalSpend ? 'topVendorsSum' : 'topVendorsTransactionCount'),
    [showTotalSpend]
  )

  const { data, isLoading } = useQuery(
    ['charts', 'getGetChart', chartType, query, dateRange],
    () => dp.getChart(chartType, { query, ...dateRange })
  )

  const vendors = useMemo(() => {
    if (isLoading || !data) return []
    return showTotalSpend
      ? data.data.map((v) => ({
          id: v.id || v.vendor.id,
          label: v.vendor.name,
          value: v.amount / 100,
          formattedValue: formatCurrency(v.amount / 100),
          country: v.vendor.territory ? v.vendor.territory.name : 'GBR',
        }))
      : data.data.map((v) => ({
          id: v.vendor.id,
          label: v.vendor.name,
          value: v.count,
          formattedValue: v.count,
          country: v.vendor.territory ? v.vendor.territory.name : 'GBR',
        }))
  }, [isLoading, data, showTotalSpend])

  if (isLoading) return <div className="h-[500px]" />
  if (!data || vendors.length === 0)
    return (
      <Card className="h-[600px] flex items-center justify-center">
        <NoData />
      </Card>
    )
  return (
    <div className="grid grid-cols-2 gap-x-4">
      <div className="pb-6">
        <TopVendorsTable
          vendors={vendors}
          t={t}
          suffix={suffix}
          showTotalSpend={showTotalSpend}
          onClick={(id) => addRule(VENDOR_TAG_ID, [id])}
        />
      </div>
      <TopVendorsChart
        data={vendors}
        showTotalSpend={showTotalSpend}
        isLoading={isLoading}
      />
    </div>
  )
}
